@import '../../../styles/color';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 3px 6px $shadow-color;
}
