@import '../../../styles/color';
@import '../../../styles/responsive';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  background-color: $footer-color;

  .logo-container {
    margin-bottom: 16px;
  }

  .menu-list {
    padding: 0;
    margin: 0 0 16px;

    li {
      list-style: none;

      a {
        padding: 0;
        font-size: 20px;
        font-weight: bold;
        color: $white;
        text-decoration: none;

        &:hover {
          opacity: 0.8;
        }

        &:focus-visible {
          opacity: 0.8;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
  }

  .sns-icon-list-container {
    width: 328px;
    margin-bottom: 16px;
  }

  .copy-right {
    margin-bottom: 10px;
    font-size: 12px;
    color: $white;
  }
}
