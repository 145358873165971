.sns-icon {
  display: block;
  padding: 6px;
  text-decoration: none;
  cursor: pointer;

  .svg-container {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .svg-container {
      opacity: 0.8;
      transform: scale(1.2);
    }
  }

  &:focus-visible {
    .svg-container {
      opacity: 0.8;
      transform: scale(1.2);
    }
  }

  &:active {
    .svg-container {
      opacity: 0.6;
      transform: scale(1.2);
    }
  }
}
