@import '../../../styles/color';

.button {
  position: relative;
  width: 48px;
  height: 48px;
  padding: 0;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  &:focus-visible {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.icon {
  position: absolute;
  inset: 0;
  width: 24px;
  height: 2px;
  margin: auto;
  background-color: $gray;
  border-radius: 1px;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: $gray;
    border-radius: 1px;
  }

  &::before {
    top: -8px;
  }

  &::after {
    top: 8px;
  }
}
