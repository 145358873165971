@import '../../../styles/color';

.container {
  position: relative;
  width: 52px;
  height: 32px;
  margin: 0;
  cursor: pointer;
  background-color: $white;
  border-radius: 16px;
}

.button {
  position: absolute;
  z-index: 1;
  width: 52px;
  height: 32px;
  border-radius: 16px;

  :global .body {
    position: relative;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    background-color: $black;
    border-radius: 50%;
    transition: transform 0.2s ease-out;
    transform: var(--color-mode-switch-button-translate);
  }
}

.sun,
.moon {
  position: absolute;
  top: 50%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  transform: translateY(-50%);
}

.sun {
  right: 6px;
}

.moon {
  left: 6px;
}
