@import '../../../styles/color';
@import '../../../styles/responsive';

.container {
  width: 100%;
  padding: 20px 10px;
  text-decoration: none;
  border-radius: 16px;
  box-shadow: 0 3px 6px $shadow-color;

  &:hover {
    opacity: 0.6;
  }

  &:focus-visible {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.4;
  }

  @include responsive(min-md) {
    width: 296px;
  }
}

.title-container {
  margin: 0 0 12px;
}

.title {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  color: $text-color;
}

.date-label {
  margin: 0;
  font-size: 14px;
  color: $text-color;
}

.tag-container {
  margin: 14px 0 0;
}
