@import '../../../styles/color';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: none;

  .icon {
    fill: $border-color;
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus-visible {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}
