$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$warning-color: var(--warning-color);
$white: var(--white);
$pale-white: var(--pale-white);
$gray: var(--gray);
$dark-gray: var(--dark-gray);
$black: var(--black);
$base-color: var(--base-color);
$text-color: var(--text-color);
$border-color: var(--border-color);
$sub-label-color: var(--sub-label-color);
$shadow-color: var(--shadow-color);
$header-color: var(--header-color);
$footer-color: var(--footer-color);
$link-color: var(--link-color);
