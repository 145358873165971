@import '../../../styles/color';
@import '../../../styles/responsive';

@mixin fixed-top-on-the-left {
  position: fixed;
  top: 0;
  left: 0;
}

@mixin wrapper-z-index {
  z-index: 10;
}

@mixin contents-z-index {
  z-index: 20;
}

.wrapper {
  @include fixed-top-on-the-left;
  @include wrapper-z-index;

  width: 0;
  height: 0;
  visibility: hidden;
  background-color: $base-color;

  &--open {
    width: calc(100vh + 100vw);
    height: calc(100vh + 100vw);
    visibility: visible;
    animation: show-wrapper 0.4s linear 0s;
  }

  &--close {
    width: 0;
    height: 0;
    visibility: hidden;
    animation: hide-wrapper 0.2s linear 0s;
  }
}

.close-button-container {
  @include fixed-top-on-the-left;
  @include contents-z-index;

  transition: all 0.1s linear 0s;
  transform: translate(-100%, -100%);

  &--open {
    top: 4px;
    left: 16px;
    transform: translate(0, 0);

    @include responsive(min-sm) {
      left: 80px;
    }
  }

  &--close {
    top: 0;
    left: 0;
    transform: translate(-100%, -100%);
  }
}

.menu-list-container {
  @include fixed-top-on-the-left;
  @include contents-z-index;

  transition: all 0.2s linear 0s;
  transform: translate(-100%, -100%);

  &--open {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--close {
    top: 0;
    left: 0;
    transform: translate(-100%, -100%);
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;

    a {
      padding: 8px;
      font-size: 20px;
      font-weight: bold;
      color: $text-color;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }

      &:focus-visible {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }
}

@keyframes show-wrapper {
  0% {
    width: 0;
    height: 0;
    visibility: hidden;
    border-radius: 0 0 calc(100vh + 100vw);
  }

  80% {
    width: calc(100vh + 100vw);
    height: calc(100vh + 100vw);
    visibility: visible;
  }

  100% {
    border-radius: 0;
  }
}

@keyframes hide-wrapper {
  0% {
    width: calc(100vh + 100vw);
    height: calc(100vh + 100vw);
    visibility: visible;
    border-radius: 0;
  }

  20% {
    border-radius: 0 0 calc(100vh + 100vw);
  }

  100% {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
